/* inter-100 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/inter-v7-latin_cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-100.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-100.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-200 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/inter-v7-latin_cyrillic-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-200.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-200.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-200.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-300 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/inter-v7-latin_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-300.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-regular - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/inter-v7-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/inter-v7-latin_cyrillic-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-regular.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-500 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/inter-v7-latin_cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-500.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-600 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/inter-v7-latin_cyrillic-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-600.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-700 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/inter-v7-latin_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-700.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-800 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/inter-v7-latin_cyrillic-800.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-800.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-800.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-800.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-800.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-800.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-900 - latin_cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/inter-v7-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/inter-v7-latin_cyrillic-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/inter-v7-latin_cyrillic-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/inter-v7-latin_cyrillic-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/inter-v7-latin_cyrillic-900.svg#Inter") format("svg"); /* Legacy iOS */
}

/* jockey-one-regular - latin */
@font-face {
  font-family: "Jockey One";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/jockey-one-v10-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/jockey-one-v10-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/jockey-one-v10-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/jockey-one-v10-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/jockey-one-v10-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/jockey-one-v10-latin-regular.svg#JockeyOne") format("svg"); /* Legacy iOS */
}
