header {
  margin: auto;
}

.container {
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .container {
    width: 740px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1440px;
  }
}

.scroll-to-top {
  position: fixed;
  left: auto;
  right: 10px;
  width: 50px;
  height: 50px;
  bottom: 12px;
  border-radius: 20%;
  border: none;
  background: rgba(66, 66, 66, 0.431372549);
  color: #ffffff;
  z-index: 1000;
  cursor: pointer;
}
.scroll-to-top__arrow-up {
  border: solid #ffffff;
  border-width: 0 6px 6px 0;
  border-radius: 4px;
  display: inline-block;
  margin-top: 12px;
  padding: 10px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.reg-button {
  width: 279px;
  height: 58px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(to right, #ff4307, #ff7448);
  transition: background 0.3s ease-out;
  outline: none;
}
.reg-button:hover {
  opacity: 0.9;
}

.logo {
  width: 80px;
}

.mappoint {
  padding: 14px 18px 14px 32px;
}

header {
  max-width: 1920px;
  margin: auto;
  padding-top: 43px;
}
header hr {
  display: none;
}
header hr:last-child {
  display: none;
}
header .burger-menu {
  display: none;
}
header .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
header .navbar__logo {
  display: flex;
  align-items: center;
  margin-left: 10.5%;
  text-decoration: none;
  cursor: pointer;
}
header .navbar__info {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 765px;
  height: 57px;
  background: #212121;
}
header .navbar__info a {
  text-decoration: none;
  color: inherit;
}
header .navbar__info hr {
  display: block;
  width: 24px;
  top: 60px;
  border: 1px solid #4e4e4e;
  transform: rotate(90deg);
}
header .navbar__contacts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: #fcfcfc;
}
header .navbar__links {
  display: flex;
}
header .navbar__links li {
  list-style-type: none;
}
header .navbar__links li:first-child {
  margin-left: 30px;
}
header .navbar__links li:last-child {
  margin-right: 30px;
}
header .navbar__links li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  list-style-type: none;
}
header .navbar__links li a {
  position: relative;
  color: #181818;
  text-decoration: none;
  padding: 10px;
  height: 57px;
  transition: all 0.5s;
}
header .navbar__links li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  content: ".";
  color: transparent;
  background: #aaa;
  height: 1px;
}
header .navbar__links li a:hover {
  color: #fff;
  z-index: 1;
}
header .navbar__links li a:hover:after {
  z-index: -10;
  animation: li 0.5s forwards;
  -webkit-animation: li 0.5s forwards;
  -moz-animation: li 0.5s forwards;
  opacity: 1;
}
@keyframes li {
  0% {
    width: 0;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #212121;
  }
}

.costs-to {
  display: flex;
  gap: 15px;
  align-items: center;
  height: 30px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.costs-to > svg {
  width: 25px;
}

.header-logo-mobile {
  display: none;
}

.header-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 220px;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 800px;
}
.header-info h3 {
  font-weight: 200;
  font-size: 20.5px;
  line-height: 29px;
}
.header-info__name {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
.header-info__requisites {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 200;
  font-size: 21px;
}
.header-info__requisites b {
  font-weight: 300;
}
.header-info__inn {
  display: flex;
  gap: 15px;
}
.header-info__ogrn {
  display: flex;
  gap: 5px;
}

.documents {
  padding-bottom: 90px;
}
.documents h1 {
  display: flex;
  justify-content: center;
  padding-top: 52px;
  padding-bottom: 21px;
  font-size: 25px;
  line-height: 36px;
  font-weight: 600;
}
.documents hr {
  margin: auto auto 25px;
  width: 75%;
  border: 1px solid #e6e6e6;
}
.documents ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
.documents ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: center;
}
.documents ul li p {
  font-size: 19px;
}
.documents__passport {
  padding-bottom: 10px;
  width: 168px;
  transition: 1s;
}
.documents__passport:hover {
  transform: scale(1.1);
}
.documents__cert {
  padding-bottom: 10px;
  width: 312px;
  transition: 1s;
}
.documents__cert:hover {
  transform: scale(1.1);
}

.vehicle-categories {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
  text-align: center;
  color: #f1f1f1;
}
.vehicle-categories__icons {
  width: 90px;
  height: 90px;
  background: #f5f5f5;
  border-radius: 21px;
  padding: 15px;
  transition: 1s;
}
.vehicle-categories h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}
.vehicle-categories h3 {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
.vehicle-categories ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
.vehicle-categories ul li {
  width: 100%;
  height: 314px;
  margin-top: 28px;
  margin-bottom: 65px;
  padding: 38px 10px 10px;
  border: 1px solid #545454;
  box-sizing: border-box;
  border-radius: 25px;
  transition: 1s;
}
.vehicle-categories ul li:not(:last-child) {
  margin-right: 30px;
}
.vehicle-categories ul li:hover {
  border: 1px solid #ffffff;
}
.vehicle-categories ul li:hover img {
  transform: scale(1.1);
}
.vehicle-categories ul h1 {
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #f1f1f1;
}
.vehicle-categories ul hr {
  margin: auto;
  width: 32px;
  height: 4px;
  border: 0;
  background: #ffa800;
}
.vehicle-categories ul h3 {
  margin-top: 11px;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
}

.features-list {
  display: flex;
  justify-content: center;
}
.features-list__icon img {
  width: 25%;
}
.features-list__images {
  display: flex;
  justify-content: center;
}
.features-list__images img {
  width: 25%;
}
.features-list__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vw;
  gap: 1vw;
}
.features-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 105px 0 105px 0;
  list-style-type: none;
}
.features-list ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px 50px 50px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 16px;
}
.features-list ul li h1 {
  padding: 1vw;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  text-align: center;
}
.features-list ul li p {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #717171;
}
.features-list ul li p a {
  color: #717171;
}

.address-pto {
  height: 0;
}

.map-yandex {
  position: relative;
  width: 99.8%;
  height: 850px;
}
.map-yandex__map-icon {
  display: none;
  position: absolute;
  z-index: 11;
}

.contacts {
  position: absolute;
  top: 228px;
  width: 401px;
  height: 415px;
  padding-top: 30px;
  padding-left: 41px;
  padding-right: 35px;
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  z-index: 10;
}
.contacts__map-icon {
  position: absolute;
  width: 77px;
  height: 77px;
  left: 325px;
  top: -35px;
  z-index: 11;
}
.contacts__info {
  display: flex;
  flex-direction: column;
  width: 270px;
}
.contacts__info h1 {
  padding-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.contacts__info hr {
  width: 308px;
  height: 0;
  border: 1px solid #ebebeb;
  margin: 13px 0 13px 0;
}
.contacts__info a {
  text-decoration: none;
  color: #000000;
}

.certificate {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 45px;
  text-align: center;
  color: #313131;
}
.certificate ul {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 140px;
}
.certificate ul li {
  flex: 25% 0 0 0;
  list-style-type: none;
}
.certificate h1 {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}
.certificate hr {
  margin: auto auto 25px;
  width: 75%;
  border: 1px solid #e6e6e6;
}
.certificate img {
  height: 380px;
  border: 15px solid rgba(172, 172, 172, 0.0509803922);
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.footer__phone {
  display: none;
}
.footer__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
}
.footer__logo .logo-footer {
  width: 135px;
}
.footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-size: 18px;
}
.footer__links li {
  list-style-position: inside;
  padding-left: 16px;
}
.footer__links li:first-child {
  list-style-type: none;
}
.footer__links li a {
  color: #ffffff;
  text-decoration: none;
}
.footer__links ::marker {
  color: #e14132;
  font-size: 16px;
}
.footer__info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding-bottom: 20px;
}
.footer__info span:first-child {
  font-size: 14px;
}
.footer__info span:last-child {
  font-size: 20px;
}
.footer__info span:last-child a {
  color: inherit;
  text-decoration: none;
}
.footer__info hr {
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #4e4e4e;
  height: 24px;
}
.footer__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  padding-bottom: 20px;
}

.modal-image {
  position: relative;
  display: flex;
  justify-content: center;
  height: 90%;
  transform: translateY(-150%);
  animation: modal-image 0.5s forwards;
}
.modal-image img {
  height: 100%;
}
.modal-image img:not(:last-child) {
  width: 100%;
  box-shadow: 10px 5px 15px 1px rgba(0, 0, 0, 0.5);
}
.modal-image #modal__close-btn {
  position: absolute;
  right: 0;
  height: 4%;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  z-index: 25;
}
.modal-image #modal__close-btn:hover {
  opacity: 0.8;
}
@keyframes modal-image {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

.send-form {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 440px;
  background: #fdfdfd;
}
.send-form__title {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 30px;
  left: 10%;
}
.send-form__title img {
  margin-right: 10px;
}
.send-form__title h1 {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}
.send-form__input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 35px;
  padding-left: 12px;
  margin-bottom: 25px;
  outline: none;
}
.send-form__input-error {
  border: 1px solid #fba3a3;
  box-shadow: 0 0 5px #fba3a3;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 35px;
  padding-left: 12px;
  margin-bottom: 25px;
  outline: none;
}
.send-form__submit {
  width: 178px;
  height: 41px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  border: none;
  border-radius: 10px;
  font-family: inherit;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  background: linear-gradient(to right, #ff4307, #ff7448);
  transition: background 0.3s ease-out;
}
.send-form__submit:disabled {
  width: 100%;
  background: #ececec;
  font-size: 0.6em;
  color: #a7a7a7;
}
.send-form__error {
  position: absolute;
  right: 10%;
  margin-left: 10px;
  font-size: 10px;
  color: red;
}
.send-form__field-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 7px;
}
.send-form__field-name p {
  margin-left: 5px;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #979797;
}
.send-form__checkbox {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  margin-right: 5px;
}
.send-form form {
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10% 10% 0 10%;
}
.send-form form .valid {
  background-size: 20px;
  background: url(../images/icons/checked.svg) no-repeat 98%;
}
.send-form form p {
  display: flex;
  align-items: center;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  transform: translateY(-150%);
  animation: send-form 0.5s forwards;
}
.modal-content #modal__close-btn {
  position: absolute;
  top: 70px;
  right: 20px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  z-index: 50;
}
@keyframes send-form {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

#burger-content {
  display: none;
}

#overlay {
  overflow-x: auto;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  transition: 3s;
}

#top {
  background: #c4c4c4 url(../images/bg-header.webp) no-repeat;
  background-size: cover;
  background-clip: border-box;
}

#documents {
  background-image: url(../images/bg-reqdoc.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

#categories {
  background: #222222 url(../images/bg-categories.webp) no-repeat;
  background-blend-mode: multiply;
  background-size: cover;
}

#epts {
  background: #c5c5c5 url(../images/bg-features.webp) no-repeat center;
  background-blend-mode: overlay;
  background-size: cover;
}

#accreditations {
  background: linear-gradient(89.06deg, #ffffff 0.77%, rgba(255, 255, 255, 0.46875) 52.38%, rgba(196, 196, 196, 0) 97.91%);
}

#footer {
  background: #212121;
}