@media (max-width: 1600px) {
  header {
    display: flex;
    justify-content: center;
    padding-top: 0;
    background: #212121;
  }
  header hr {
    display: block;
    height: 42px;
    top: 60px;
    border: 1px solid #4e4e4e;
    transform: rotate(0deg);
  }
  header .navbar__logo {
    margin-left: 5px;
  }
  header .navbar__links li:first-child {
    margin-left: 20px;
  }
  header .navbar__links li:last-child {
    margin-right: 20px;
  }
  header .navbar__links li a {
    color: #ffffff;
    height: 49px;
  }
  header .navbar__links li a:after {
    background: linear-gradient(to right, #ff4307, #ff7448);
  }
  @keyframes li {
    0% {
      width: 0;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #ff4307, #ff7448);
    }
  }
  header .navbar__info {
    width: 100%;
    height: 50px;
  }
  header .navbar__info hr {
    display: none;
  }
  header .navbar__contacts {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .header__info {
    padding-bottom: 55px;
  }
  .vehicle-categories ul h3 {
    font-size: 8px;
    line-height: 13px;
  }
  .features-list ul li:not(:nth-child(even)) {
    margin-right: 20px;
  }
  .features-list ul li:not(:last-child) {
    margin-bottom: 20px;
  }
  #top {
    background-blend-mode: multiply;
  }
}
@media (max-width: 1300px) {
  .vehicle-categories ul h3 {
    font-size: 8px;
    line-height: 13px;
  }
  .features-list ul li:not(:nth-child(even)) {
    margin-right: 0;
  }
  .features-list ul li:not(:last-child) {
    margin-bottom: 32px;
  }
  #top {
    background-blend-mode: multiply;
  }
}
@media (max-width: 1140px) {
  .logo-label {
    font-size: 25px;
  }
  .navbar__links li:first-child {
    margin-left: 10px;
  }
  .navbar__links li:last-child {
    margin-right: 10px;
  }
  .navbar__links li a {
    font-size: 11px;
    color: #ffffff;
    height: 49px;
  }
  .navbar__info span:first-child {
    font-size: 12px;
  }
  .vehicle-categories ul h3 {
    font-size: 12px;
    line-height: 15px;
  }
  .features-list ul li:not(:nth-child(even)) {
    margin-right: 0;
  }
  .features-list ul li:not(:last-child) {
    margin-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .logo {
    margin-top: 8px;
    margin-right: 6px;
    width: 25px;
  }
  .logo-label {
    font-size: 25px;
  }
  header {
    height: 50px;
  }
  header hr {
    display: block;
    margin-right: 20px;
    margin-left: 20px;
    height: 24px;
    top: 60px;
    border: 1px solid #4e4e4e;
    transform: rotate(0deg);
  }
  header hr:last-child {
    position: absolute;
    display: inline;
    top: 50px;
    height: 1px;
    width: 100%;
    margin: auto;
    border: 1px solid #373737;
    z-index: 2;
  }
  header .burger-menu {
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid #363636;
    border-radius: 50%;
    margin-top: 10px;
    margin-left: 10px;
    z-index: 10;
  }
  header .burger-menu img {
    display: block;
    margin: auto;
    padding-top: 25%;
  }
  header .navbar__links {
    display: none;
  }
  header .navbar__info {
    width: 100%;
    height: 0;
  }
  header .navbar__info span:first-child {
    font-size: 8px;
  }
  header .navbar__info span:last-child {
    font-size: 13px;
  }
  header .navbar__info hr {
    display: none;
  }
  header .navbar__contacts {
    align-items: flex-end;
  }
  header .navbar .mappoint {
    position: absolute;
    top: 5px;
    height: 11px;
    padding: 0;
  }
  .header-info__name {
    font-size: 20px;
    line-height: 24px;
  }
  .header-info__requisites {
    gap: 5px;
    font-size: 10.5px;
  }
  .vehicle-categories ul h3 {
    font-size: 8px;
    line-height: 10px;
  }
  .map-yandex {
    height: 274px;
    margin-bottom: 350px;
  }
  .map-yandex__map-icon {
    position: absolute;
    display: block;
    top: -40px;
    right: 8px;
  }
  .contacts {
    top: 275px;
    width: 330px;
    padding-left: 15px;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }
  .contacts__map-icon {
    display: none;
  }
  .contacts__info {
    width: 100%;
    font-size: 12px;
  }
  .contacts__info h1 {
    font-size: 18px;
    text-transform: none;
  }
  .certificate img {
    height: 230px;
  }
  .send-form {
    height: 400px;
  }
  .send-form input {
    overflow: visible;
  }
  .send-form hr {
    display: none;
  }
  .send-form__title {
    position: absolute;
  }
  .send-form__title h1 {
    font-size: 13px;
  }
  .send-form__error {
    position: relative;
    margin-left: 10%;
  }
  .send-form__field-name {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 11px;
    line-height: 12px;
  }
  .send-form__field-name p {
    display: none;
  }
  .send-form__checkbox:last-child {
    color: darkblue;
  }
  .modal-content #modal__close-btn {
    top: 60px;
    right: 10px;
    height: 32px;
  }
  #burger-content {
    display: block;
    position: absolute;
    padding: 5% 0 0 10%;
    width: 100%;
    height: 590px;
    left: 0;
    top: 50px;
    background: #212121;
    transform: translateY(-150%);
    animation: all 0.8s forwards;
  }
  #burger-content li {
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 70px;
    list-style-position: inside;
    padding-left: 16px;
  }
  #burger-content li a {
    color: #e3e3e3;
    text-decoration: none;
  }
  #burger-content ::marker {
    color: #e3e3e3;
    font-size: 17px;
  }
  @keyframes all {
    0% {
      transform: translateX(-150%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
@media (max-width: 475px) {
  .header-logo-mobile {
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 0;
  }
  .header-info {
    display: flex;
    align-items: center;
    width: 100%;
    color: #141414;
    text-shadow: none;
    padding-bottom: 25px;
  }
  .header-info h3 {
    margin-bottom: 8px;
    width: 60%;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
  }
  .header-info__name {
    padding-top: 10px;
    text-align: center;
    font-size: 15px;
  }
  .header-info__requisites {
    align-items: center;
  }
  .header-info__inn {
    display: flex;
    gap: 5px;
  }
  .header-info__ogrn {
    display: flex;
    gap: 5px;
  }

  .costs-to {
    color: black;
  }

  .reg-button {
    width: 179px;
    height: 37px;
    font-size: 13px;
  }
  .documents {
    padding-bottom: 25px;
  }
  .documents hr {
    display: none;
  }
  .documents h1 {
    padding-top: 25px;
    font-size: 18px;
    line-height: 15px;
  }
  .documents ul li p {
    font-size: 12px;
  }
  .documents__passport {
    padding-bottom: 10px;
    width: 89px;
  }
  .documents__cert {
    padding-bottom: 10px;
    width: 106px;
  }
  .vehicle-categories {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: left;
    color: #363636;
  }
  .vehicle-categories h1 {
    padding-left: 15px;
    font-size: 18px;
    line-height: 18px;
  }
  .vehicle-categories h3 {
    padding-left: 15px;
    font-size: 10px;
    line-height: 8px;
    color: #939393;
  }
  .vehicle-categories ul {
    flex-direction: column;
    justify-content: space-between;
  }
  .vehicle-categories ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 52px;
    border: none;
  }
  .vehicle-categories ul li:not(:last-child) {
    margin-right: 0;
  }
  .vehicle-categories ul li h1 {
    color: #363636;
    font-size: 18px;
    padding: 10px;
  }
  .vehicle-categories ul li h3 {
    color: #363636;
    margin-top: 0;
    font-size: 10px;
  }
  .vehicle-categories ul li hr {
    display: none;
  }
  .vehicle-categories ul li:hover {
    border: none;
  }
  .vehicle-categories ul li:hover img {
    transform: none;
  }
  .features-list__images {
    width: 100%;
  }
  .features-list ul li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 4vh;
    padding: 1.563rem 0.625rem 1.563rem 0.625rem;
  }
  .features-list ul li h1 {
    font-size: 1.25rem;
    line-height: 0.85rem;
  }
  .features-list ul li p {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .contacts .reg-button {
    margin-top: 6px;
    margin-left: 25%;
    width: 192px;
    height: 40px;
  }
  .certificate {
    text-align: left;
  }
  .certificate hr {
    display: none;
  }
  .certificate h1 {
    padding-left: 15px;
    font-size: 18px;
    margin-bottom: 0;
  }
  .footer__phone {
    display: block;
    position: absolute;
    left: 0;
    bottom: 229px;
  }
  .footer__phone a {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #212121;
    text-decoration: none;
  }
  .footer__logo {
    margin-top: 6px;
  }
  .footer__logo .logo-footer {
    padding-top: 10px;
    width: 80px;
  }
  .footer__links {
    flex-wrap: wrap;
    font-size: 10px;
    line-height: 25px;
    width: 320px;
  }
  .footer__links li {
    padding-left: 8px;
  }
  .footer__links li:nth-child(3) {
    list-style-type: none;
  }
  .footer__links ::marker {
    font-size: 8px;
  }
  .footer__info {
    display: none;
  }
  .footer__copyright {
    font-size: 8px;
    line-height: 10px;
    padding-bottom: 15px;
  }
  .modal-image {
    height: 70%;
    width: 80%;
  }
  .modal-image #modal__close-btn {
    height: 6%;
  }
  #top {
    background: none;
  }
  #documents {
    margin: 0 18px 22px 18px;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 14px;
    background: url(../images/bg-reqdoc.webp) no-repeat;
    background-size: cover;
  }
  #categories {
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    background: none;
  }
  #epts {
    border-bottom: 1px solid #EDEDED;
    background-position: right;
  }
}