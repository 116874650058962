*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: "Inter", serif;
}

body {
  font-family: "Inter", serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #d4d4d4;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-thumb {
  background: #ff7448;
  border-radius: 4px;
  border: 1px solid #d4d4d4;

  &:hover {
    background: #ff4307;
  }
}
